import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { CloseIcon } from '@storefront/ui-components/shared/icons';
import { type ModalNames } from '@storefront/util/analytics/types';
import { useSendEvent } from '@storefront/util/analytics/useAnalytics';
import { imageProxyURL } from '@storefront/util/img-proxy';
import clsx from 'clsx';
import { type FC, Fragment, type PropsWithChildren, useEffect, useMemo } from 'react';
import { IconButton } from '../buttons/IconButton';

export interface ModalProps extends PropsWithChildren {
  name: ModalNames;
  className?: string;
  title?: string | React.ReactNode;
  subheading?: string;
  isOpen?: boolean;
  onClose: () => void;
  preventClickOutside?: boolean;
}

export const Modal: FC<ModalProps> = ({
  name,
  className,
  title,
  subheading,
  isOpen,
  onClose,
  children,
  preventClickOutside,
}) => {
  const open = useMemo(() => isOpen, [isOpen]);
  const sendModalOpenedEvent = useSendEvent('modal_opened');
  const sendModalClosedEvent = useSendEvent('modal_closed');

  const handleClose = () => {
    if (onClose) onClose();
    sendModalClosedEvent({ name });
  };

  useEffect(() => {
    if (open) sendModalOpenedEvent({ name });
  }, [open]);

  return (
    <Transition show={Boolean(open)} as={Fragment}>
      <Dialog
        id={`${name.replaceAll(' ', '-').toLowerCase()}-modal`}
        as="div"
        className="relative z-40"
        onClose={() => {
          // Note: This also prevents the escape to close keyboard shortcut
          if (preventClickOutside) return;
          handleClose();
        }}
      >
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-400/75" aria-hidden="true" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={clsx(
                  'max-w-[95vw] modal-content relative w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-lg',
                  className,
                )}
              >
                {title || subheading ? (
                  <div
                    className={'text-white h-24 bg-[rgba(248,140,41,0.50)]'}
                    style={{
                      backgroundImage: `url(${imageProxyURL('/assets/images/modal-header.png?url')})`,
                    }}
                  >
                    <div className="absolute top-0 right-0 pt-2 pr-2">
                      <IconButton
                        className="w-6 h-6"
                        icon={() => <CloseIcon />}
                        type="button"
                        aria-label="Close dialog"
                        onClick={handleClose}
                      />
                    </div>
                    <div className="text-center flex items-center justify-center h-full">
                      <div>
                        <h2 className="text-2xl font-bold">{title}</h2>
                        {subheading && <h5 className="text-base font-bold">{subheading}</h5>}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="p-6">{children}</div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
